import Image from "next/image";
import React, { useRef } from "react";
import homeBg from "./homepage/home-bg.png";
import widget1 from "./homepage/home-widget-1.png";
import widget2 from "./homepage/home-widget-2.png";
import mobile from "./homepage/Hero_Illustration_asset_mobile.png";
import { motion } from "framer-motion";
const Variants = {
  fadeUpInitial: {
    opacity: 0,
    y: 10
  },
  fadeUp: {
    opacity: 1,
    y: 0
  }
};
export const HomepageImage = () => {
  const ref = useRef(null);
  return <div className="relative lg:absolute flex flex-col inset-0 mt-10 lg:mt-0 z-0" ref={ref} data-sentry-component="HomepageImage" data-sentry-source-file="HomepageImage.tsx">
      <div className="hidden lg:block absolute bottom-14 right-0 max-h-[400px] lg:max-h-screen">
        <Image width={homeBg.width / 2} height={homeBg.height / 2} alt="Man holding envelope on top of mobile phone" src={homeBg} quality={100} priority data-sentry-element="Image" data-sentry-source-file="HomepageImage.tsx" />
        <motion.div className="absolute" style={{
        bottom: 310,
        left: 105
      }} transition={{
        delay: 0.4
      }} variants={Variants} initial="fadeUpInitial" animate="fadeUp" data-sentry-element="unknown" data-sentry-source-file="HomepageImage.tsx">
          <Image className="relative scale-75 -left-30 top-30 lg:scale-100 lg:left-0 lg:top-0" height={widget1.height / 2} width={widget1.width / 2} alt="A UI widget with asks, What can we help you with? and the options are The Funeral, Applying for Benefits, and Identity Theft" src={widget1} quality={100} data-sentry-element="Image" data-sentry-source-file="HomepageImage.tsx" />
        </motion.div>
        <motion.div className="absolute" style={{
        bottom: 196,
        left: 158
      }} transition={{
        delay: 0.7
      }} variants={Variants} initial="fadeUpInitial" animate="fadeUp" data-sentry-element="unknown" data-sentry-source-file="HomepageImage.tsx">
          <Image className="relative scale-100 -left-0 top-10 lg:scale-100 lg:left-0 lg:top-0" height={widget2.height / 2} width={widget2.width / 2} alt="A UI widget with a chat message from Grace, a care manager that says - I'll walk you through it step by step" src={widget2} quality={100} data-sentry-element="Image" data-sentry-source-file="HomepageImage.tsx" />
        </motion.div>
      </div>
      <Image className="lg:hidden flex-1 self-center" alt="Man holding envelope on top of mobile phone with widgets showing value of app" src={mobile} quality={100} priority data-sentry-element="Image" data-sentry-source-file="HomepageImage.tsx" />
    </div>;
};